import React, { useState } from 'react';
import Logo from '../../images/logo.png';

// import '../../styles/global.scss';
import '../../styles/onboarding.scss';

export default function OnboardingQuestions({ nextStep }) {
    const [current, setCurrent] = useState(0);

    const questions = [
        {
            //changes depending on mi/km selection on welcome screen
            questionText: 'How many miles did you drive in the last year?',
            answerOptions: [
                { answerText: 'Zero' },
                { answerText: 'Less than 5,000' },
                { answerText: 'Between 5,000-10,000' },
                { answerText: 'More than 10,000' },
            ],
        },
        {
            questionText: 'How many roundtrip flights have you taken in the last year?',
            answerOptions: [
                { answerText: 'Zero' },
                { answerText: 'One to two' },
                { answerText: 'Three to five' },
                { answerText: 'More than five' },
            ],
        },
        {
            questionText: 'How many days per week do you eat meat or seafood?',
            answerOptions: [
                { answerText: 'Zero' },
                { answerText: 'One to three' },
                { answerText: 'Four to six' },
                { answerText: 'Everyday' },
            ],
        },
        {
            questionText:
                'How is your electricity powered? (examples of clean energy are solar and wind)',
            answerOptions: [
                { answerText: '100% clean' },
                { answerText: '50% clean' },
                { answerText: 'Not clean' },
                { answerText: 'Use local average' },
            ],
        },
        {
            questionText:
                'Spending on new stuff per month\n(clothes, furniture, and other physical goods)?',
            answerOptions: [
                { answerText: 'Less than $100' },
                { answerText: 'Between $150 and $750' },
                { answerText: 'Between $750 and $1,500' },
                { answerText: 'More than $1,500' },
            ],
        },
    ];

    const handleAnswerButtonClick = answerOption => {
        const next = current + 1;
        if (next < questions.length) {
            setCurrent(next);
        } else {
            nextStep();
        }
    };

    return (
        <div className="onboarding">
            <img className="onboarding-logo-small" src={Logo}></img>
            <div className="question-section">
                <div className="question-text">{questions[current].questionText}</div>
            </div>
            <p className="onboarding-subtext">
                Answer as best as you can <br />
                You can change or refine your answers after setup
            </p>

            <div className="answer-section" style={{ backgroundColor: '1F34AD' }}>
                {questions[current].answerOptions.map((answerOption, index) => (
                    <button onClick={() => handleAnswerButtonClick()}>
                        {answerOption.answerText}
                    </button>
                ))}
            </div>
            {/* progress bar circles */}
            <div class="progress-container">
                <div class="progress-circle-container" id="progress-circle-container">
                    {questions.map((e, i) => (
                        <div class={i <= current ? 'circle active' : 'circle'}></div>
                    ))}
                </div>
            </div>
        </div>
    );
}

import React from 'react';
import Logo from '../../images/logo.png';
import Waves from '../../images/waves.png';
// import '../../styles/global.scss';
import '../../styles/onboarding.scss';

const PreOnboarding = ({ nextStep, handleChange, values }) => {
    const Continue = e => {
        e.preventDefault();
        nextStep();
    };
    return (
        <div className="onboarding">
            <div className="waves-png-container">
                <img className="waves-png" src={Waves} />
            </div>
            <div>
                <img className="onboarding-logo" src={Logo}></img>
                <h1>Welcome to the Movement!</h1>
            </div>
            <div className="onboarding-text">
                <p>
                    Answer a few questions to start. Your answers will create an initial, rough
                    estimate of your carbon pollution.
                </p>
                <p className="onboarding-text-p">
                    All answers are correct. This is not about being perfect.
                    <br /> This is about helping you decide how you want to act.
                </p>
            </div>
            <button className="onboarding-go-button" onClick={Continue}>
                Let's Go!
            </button>
            <div className="waves-png-bottom-container">
                <img className="waves-png-bottom" src={Waves} />
            </div>
        </div>
    );
};

export default PreOnboarding;

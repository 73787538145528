import React, { Component } from 'react';
import PreOnboarding from '../components/Onboarding/PreOnboarding';
import PostOnboarding from '../components/Onboarding/PostOnboarding';
import PostOnboarding2 from '../components/Onboarding/PostOnboarding2';
import OnboardingQuestions from '../components/Onboarding/OnboardingQuestions';
import EmissionResult from '../components/Emissions/EmissionResult';
import { navigate } from 'gatsby';

export default class Onboarding extends Component {
    state = {
        step: 1,
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    };

    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    };
    render() {
        const { step } = this.state;

        switch (step) {
            case 1:
                return <PreOnboarding nextStep={this.nextStep} handleChange={this.handleChange} />;
            // case 2:
            //   return (
            //     <CountryQuestions
            //       nextStep={this.nextStep}
            //       handleChange={this.handleChange}
            //       values={values}
            //     />
            //   )
            case 2:
                return (
                    <OnboardingQuestions
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.values}
                    />
                );
            case 3:
                return (
                    <PostOnboarding
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={this.values}
                    />
                );
            case 4:
                try {
                    return (
                        <EmissionResult
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            values={this.values}
                        />
                    );
                } catch (error) {
                    navigate('/profile/myemissions');
                }

            // case 5:
            //   return (
            //     <PostOnboarding2
            //       nextStep={this.nextStep}
            //       prevStep={this.prevStep}
            //       handleChange={this.handleChange}
            //       values={this.values}
            //     />
            //   )
            //   case 6:
            //     navigate('/profile');
            default:
        }
    }
}

// import { navigate } from 'gatsby-link';
import React from 'react';
import Logo from '../../images/logo.png';
import Festival from '../../images/undraw_festivities_tvvj 1.png';
import { navigate } from 'gatsby';

const PostOnboarding = ({ nextStep, handleChange, values }) => {
    const Continue = e => {
        e.preventDefault();
        nextStep();
    };
    const ShortcutToProfile = () => {
        navigate('/profile/myemissions');
    };

    return (
        <div className="onboarding">
            <img className="onboarding-logo" src={Logo}></img>
            <img className="postOnboarding-image" src={Festival}></img>
            <p className="postOnboarding-p">That was easy!</p>
            <p className="onboarding-text">
                Now see how you compare to your fellow citizens and humans on our shared planet!
            </p>
            <h3 className="onboarding-text-ready">Ready?</h3>
            <button className="onboarding-go-button" onClick={ShortcutToProfile}>
                Next
            </button>
        </div>
    );
};

export default PostOnboarding;
